import {
    UserManager,
    User,
    UserLoginManager,
    LocalUserManager,
    UserLogOutManager,
    CanLogIn, CanLogOut,
    Authenticate,
    UserLoginError,
    UserAuthorityConfig
} from "../../lib/user";
import { WithConfig } from "../../lib/util/withConfig";
import * as Msal from "msal";

export interface MsalUserConfig extends UserAuthorityConfig {
    msalInstance: Msal.UserAgentApplication
    msalConfig: Msal.Configuration
    requestObj: {}
    clientId: String
    localStorageAccountKey: String
    authority: String
};

class MsalUserManager extends Authenticate implements UserManager, CanLogIn<Promise<MsalUser>>, CanLogOut<void>{
    user: MsalUser;
    loginManager: MsalUserLoginManager;
    logOutManager: MsalUserLogOutManager;
    localUserManager: MsalLocalUserManager;
    config: MsalUserConfig;
    /**
     *
     */
    constructor(config: MsalUserConfig) {
        super()
        this.config = config;
        this.user = new MsalUser();
        this.loginManager = new MsalUserLoginManager(this.config);
        this.logOutManager = new MsalUserLogOutManager(this.config);
        this.localUserManager = new MsalLocalUserManager(this.config);
    }
}

interface MsalUser {
    data: Msal.Account
}

class MsalUser implements User {
    /**
     * 
     */
    constructor(_data?: Msal.Account | any, rawTokenId: string = '') {
        this.data = _data;
        this.rawTokenId = rawTokenId;
    }
    //@override
    data: Msal.Account
    rawTokenId: string
}

export class MsalLoginError implements UserLoginError<Msal.ClientAuthError>{
    message: string;
    payload: Msal.ClientAuthError;

    constructor(_message: string, _payload: Msal.ClientAuthError) {
        this.message = _message;
        this.payload = _payload;
    }
}

class MsalUserLoginManager extends WithConfig<MsalUserConfig> implements UserLoginManager<Promise<MsalUser>> {
    /**
     *
     */
    constructor(_config: MsalUserConfig) {
        super(_config);
    }

    getPendingLogins() {
        return this.config.msalInstance.getLoginInProgress()
    }

    // _onOk(res: Msal.AuthResponse): MsalUser {
    //     return new MsalUser(res);
    // }

    getAlreadyLoggedInAccount(): Msal.Account | null {
        return this.config.msalInstance.getAccount()
    }

    autoLogin(){
        return new Promise<MsalUser>(async (resolve, reject) => {
            const account: Msal.Account | null = this.getAlreadyLoggedInAccount();
            try{
                if(account){
                    let with_token = await this.config.msalInstance.acquireTokenSilent(this.config.requestObj);
                    resolve(new MsalUser(with_token.account, with_token.idToken.rawIdToken))
                }else{
                    reject('no already logged accounts')
                }
            }catch(err){
                reject(err)
            }
        })
    }

    //@override
    logIn() {
        return new Promise<MsalUser>(async (resolve, reject) => {
            try {
                let with_token: Msal.AuthResponse = await this.config.msalInstance.loginPopup();
                console.log({with_token})
                resolve(new MsalUser(with_token.account, with_token.idToken.rawIdToken))
            }
            catch (err) {
                reject(err)
            };
        })
    }
}

class MsalUserLogOutManager extends WithConfig<MsalUserConfig> implements UserLogOutManager<void>{
    /**
    *
    */
    constructor(_config: MsalUserConfig) {
        super(_config);
    }
    //@override
    logOut() {
        // return new Promise<void>(resolve => this.config.msalInstance.logout());
        this.config.msalInstance.logout()
    }
}

class MsalLocalUserManager extends WithConfig<MsalUserConfig> implements LocalUserManager {
    /**
     *
     */
    constructor(_config: MsalUserConfig) {
        super(_config);
    }

    clearData() {
        ///not implemented
    }

    //@override
    setData() { }

    //@override
    getData(): Msal.Account {
        return this.config.msalInstance.getAccount();
    }
}

export {
    MsalUser,
    MsalUserManager,
    MsalLocalUserManager,
    MsalUserLoginManager,
    MsalUserLogOutManager,
}