import { LionUserConfig } from './manager';
import { HttpClient } from '../common';

export default class LionAuthConfig implements LionUserConfig{
    localStorageAccountKey: string;
    httpClient: HttpClient
    /**
     *
     */
    constructor(httpClient: HttpClient) {
        this.localStorageAccountKey = 'lion_user';
        this.httpClient = httpClient;
    }
}
