import {
    UserManager,
    User,
    UserLoginManager,
    LocalUserManager,
    UserLogOutManager,
    CanLogIn, CanLogOut,
    Authenticate,
    UserLoginError,
    UserAuthorityConfig
} from "../../lib/user";
import { HttpClient, HttpClientError, HttpClientResponse } from '../common';
import { WithConfig } from "../../lib/util/withConfig";
import LionAuthenticationManager from "./auth";

export interface LionUserConfig extends UserAuthorityConfig{
    localStorageAccountKey: string
    httpClient: HttpClient 
}

class LionUserManager extends Authenticate implements UserManager, CanLogIn<Promise<LionUser>>, CanLogOut<void>{
    user: LionUser;
    loginManager: LionUserLoginManager;
    logOutManager: LionUserLogOutManager;
    localUserManager: LionLocalUserManager;
    authenticationManager: LionAuthenticationManager
    config: LionUserConfig;
    /**
     *
     */
    constructor(config: LionUserConfig) {
        super()
        this.config = config;
        this.user = new LionUser();
        this.loginManager = new LionUserLoginManager(this.config);
        this.logOutManager = new LionUserLogOutManager(this.config);
        this.localUserManager = new LionLocalUserManager(this.config);
        this.authenticationManager = new LionAuthenticationManager(this.user, this.config);
    }
}

class LionUser implements User {
    /**
     * 
     */
    constructor(_data?: any, name?: string) {
        this.data = _data;
        this.name = name
    }

    //@override
    data?: any
    name?: string
}

export class LionLoginError implements LionUserLoginError {
    message: string;
    payload: HttpClientError;

    constructor(_message: string, _payload: HttpClientError) {
        this.message = _message;
        this.payload = _payload;
    }
}

class LionUserLoginManager extends WithConfig<LionUserConfig> implements UserLoginManager<Promise<LionUser>> {
    /**
     *
     */
    constructor(_config: LionUserConfig) {
        super(_config);
    }

    //@override
    logIn(loginData: LionUserLoginData) {

        const form = new FormData();
        form.append("grant_type", "password");
        form.append("username", loginData.userName);
        form.append("password", loginData.password);

        return new Promise<LionUser>(async (resolve, reject) => {
            let _lionAuthResp: HttpClientResponse;
            let _lionAuthError: HttpClientError;

            try {
                _lionAuthResp = await this.config.httpClient.post("/oauth/token", form, {});
                resolve(new LionUser(_lionAuthResp.data, loginData.userName))
            }
            catch (err) {
                _lionAuthError = err;
                reject(new LionLoginError(_lionAuthError.message, _lionAuthError))
            };
        })
    }
}

class LionUserLogOutManager extends WithConfig<LionUserConfig> implements UserLogOutManager<void>{
    /**
    *
    */
    constructor(_config: LionUserConfig) {
        super(_config);
    }
    //@override
    logOut() {
        // return new Promise<void>(resolve => this.config.LionInstance.logout());
    }
}

class LionLocalUserManager extends WithConfig<LionUserConfig> implements LocalUserManager {
    /**
     *
     */
    constructor(_config: LionUserConfig) {
        super(_config);
    }

    //@override
    setData(data: any) {
        localStorage.setItem(this.config.localStorageAccountKey, JSON.stringify(data))
    }

    clearData() {
        localStorage.removeItem(this.config.localStorageAccountKey);
    }

    //@override
    getData(): LionUser {
        return JSON.parse(localStorage.getItem(this.config.localStorageAccountKey) as string) as LionUser;
    }
}

export interface LionUserLoginError extends UserLoginError<HttpClientError> { }

export interface LionUserLoginData {
    password: string | Blob,
    userName: string | any
}

export {
    LionUserManager,
    LionUser,
    LionUserLoginManager,
    LionUserLogOutManager,
    LionLocalUserManager
}